@import '../../styles/style.scss';

.nav-bar {
    .toolbar {
        padding: 0 1rem;
        display: flex;
        height: 40px !important;
        background-color: #FF0050 !important;
        justify-content: space-between;
        position: relative;
        .actions-container {
            color: black;
            display: flex;
            .btn-menu {
                margin-left: 1rem;
            }
        }
    }
}
