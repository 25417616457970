
.slider-container {
    height: 100vh;
    max-height: 100vh;
    .content-container {
        max-height: 100%!important;
        max-width: 100%!important;
        overflow-y: hidden;
    }
    .image {
        max-height: 100%;
        width: 100%;
        object-fit: cover;
    }
}