
@import '../../styles/style.scss';

.img-back{
	/* background-image: linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.1)) ,url("../../assets/images/dashboard.jpg") !important; */
	height: 100vh !important;
	background-position: center !important; 
	background-size: cover !important; 
}

.MuiOtpInput-TextField{	
	background-color: white !important;
	width: 60px !important;
}


.login-container {
	.logo {
			height: 4rem;
			width: auto;
	}
	.title {
			font-weight: $semibold;
	}
	.title-pink {
			font-weight: $semibold;
			color: $primary;
	}
	.r-side {
			background-color: #ececec;
			min-height: 100vh;
	}
	.btn-login {
			border-radius: 25px;
	}
	.info {
			color: $secondary;
			cursor: pointer !important;
	}
}